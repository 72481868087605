import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models/user';
import firebase from 'firebase/compat/app';
import { ActionCodeSettings } from 'firebase-admin/lib/auth';
import { from, lastValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, defaultIfEmpty, filter, first, map, switchMap } from 'rxjs/operators';

import AuthError = firebase.auth.AuthError;
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { UserService } from '@sentinels/services/firebase/user.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';

import { AuthEmailLogin, AuthGoogleLogin, AuthLogout, AuthWatchOnlineStatus } from '../../../../sentinels/src/lib/state/features/auth/actions';
import { APP_STATE } from '../../../../sentinels/src/lib/state/state';

export const enum StatusEnum {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export interface AuthResults {
  newUser: boolean | null;
  user: GthUserModel | null;
  success: boolean;
  errorType?: LoginError,
}

export enum LoginError {
  Unknown,
  CancelledByUser,
  NetworkError,
  InvalidUser,
  NoPasswordSet
}

export interface UserStatus {
  state: StatusEnum;
  // eslint-disable-next-line
  last_changed: Object;
}

const USERNAME_STORE = 'USERNAME_STORE';

export const isOfflineForDatabase: UserStatus = {
  state: StatusEnum.OFFLINE,
  last_changed: firebase.database.ServerValue.TIMESTAMP,
};

export const isOnlineForDatabase = {
  state: StatusEnum.ONLINE,
  last_changed: firebase.database.ServerValue.TIMESTAMP,
};

// Use this to confirm if profile is a guest.
export const GUEST_PROFILE_ID = 'guest-profile-id';

const guestProfileModel = new GthUserModel(
  GUEST_PROFILE_ID,
  {
    uid: GUEST_PROFILE_ID,
    displayName: 'Guest',
    fullName: 'Guest',
    email: 'guest@gametimehero.fake',
    token: 'fake-token',
    photoURL: 'assets/avatars/guest-icon.png',
    emailVerified: true,
    defaultCity: null,
    createdAt: firebase.firestore.Timestamp.now(),
    updatedAt: firebase.firestore.Timestamp.now(),
  },
);

export const DEFAULT_CURRENT_USER = {
  uid: 'guest',
};

@Injectable({ providedIn: 'root' })
export class GthAuthService {
  guestProfile = guestProfileModel;

  // TODO (rkara): move cloudFunctions
  get currentUserModel$(): Observable<GthUserModel | undefined> {
    return this.getCurrentUser$().pipe(
      switchMap((user) => {
        if (!user || !user.email) {
          return of(undefined);
        }
        return this.usersService.getUserByEmail$(user.email);
      }),
    );
  }

  get userModel$(): Observable<GthUserModel | null> {
    return this.store.select(selectUser).pipe(
      filter((user) => user !== undefined),
      defaultIfEmpty(null),
    );
  }

  get userModelWithGuestProfile$(): Observable<GthUserModel> {
    return this.userModel$.pipe(
      switchMap((user) => {
        if (user) {
          return of(user);
        } else {
          return of(this.guestProfile);
        }
      }),
    );
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.userModel$.pipe(map((user) => {
      return !!user;
    }));
  }

  get emailVerified$() {
    return this.emailVerifiedSubject.asObservable();
  }

  private emailVerifiedSubject = new Subject<boolean>();

  constructor(
    public afAuth: AngularFireAuth,
    private safeStorage: SrvSafeStorageService,
    private store: Store<APP_STATE>,
    @Inject(PLATFORM_ID) private platformId: unknown,
    private usersService: UserService,
  ) {
    this.storeWatchOnlineStatus();
  }

  getUserModelAsync(): Promise<GthUserModel> {
    return lastValueFrom(this.userModel$.pipe(first()));
  }

  getCurrentUser$(): Observable<User | null | undefined> {
    return new Observable<User | null | undefined>((observer) => {
      this.afAuth.onAuthStateChanged((user: any) => {
        if (user === null) {
          observer.next(null);
          return;
        }
        observer.next(user._delegate as unknown as User);
      });
    });
  }

  getAuthStateUser$() {
    return this.afAuth.authState.pipe(
      map((user) => {
        if (!isPlatformBrowser(this.platformId)) {
          return undefined;
        }
        if (user) {
          return user;
        }
        return null;
      }),
    );
  }

  // Sign up with email/password
  signUpWithEmail$(email: string, password: string) {
    const signup$ = from(this.afAuth
      .createUserWithEmailAndPassword(email, password));
    return signup$.pipe(
      switchMap(() => this.sendVerificationMail$()),
      catchError((err) => {
        console.error('Error signing up user via email');
        console.error(err);
        return of(false);
      }),
    );
  }

  // TODO (rkara): move cloudFunctions
  resendVerificationMail$(email: string, password: string) {
    const signIn$ = from(this.afAuth.signInWithEmailAndPassword(email, password));
    return signIn$.pipe(
      switchMap((providerUser) => {
        if (!providerUser || !providerUser.user || !providerUser.user.email) {
          return of(undefined);
        }
        return this.usersService.getUserByEmail$(providerUser.user.email);
      }),
      map((user) => {
        if (user && !user.emailVerified) {
          return false;
        }
        return true;
      }),
      switchMap((verified) => {
        if (verified) {
          return of(true);
        }
        return this.sendVerificationMail$();
      }),
      catchError((err) => of(false)),
    );
  }

  sendVerificationMail$() {
    const user = firebase.auth().currentUser!;
    this.emailVerifiedSubject.next(false);
    return from(user.sendEmailVerification()).pipe(
      map(() => {
        const onIdTokenChangedUnsubscribe = firebase.auth().onIdTokenChanged((user) => {
          const unsubscribeSetInterval = setTimeout(() => {
            firebase.auth().currentUser.reload();
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
          }, 5000);

          if (user && user.emailVerified) {
            clearInterval(unsubscribeSetInterval);
            this.emailVerifiedSubject.next(true);
            return onIdTokenChangedUnsubscribe();
          }
        });
        return true;
      }),
      catchError((err) => {
        console.error('Error sending email verification');
        console.error(err);
        return of(false);
      }),
    );
  }

  storeGoogleLogin() {
    this.store.dispatch(AuthGoogleLogin());
  }
  storeEmailLogin(email: string, password: string) {
    this.store.dispatch(AuthEmailLogin({ email, password }));
  }
  storeListenAuthState() {
    return this.store.select((state) => state.authFeature);
  }
  storeWatchOnlineStatus() {
    this.store.dispatch(AuthWatchOnlineStatus());
  }
  storeLogout() {
    return this.store.dispatch(AuthLogout());
  }

  // TODO (rkara): move cloudFunctions
  isUserSaved$(email: string): Observable<boolean> {
    return this.usersService.getUserByEmail$(email).pipe(
      map((user) => !!user),
    );
  }

  // TODO (rkara): move cloudFunctions
  isNewUser$(email: string) {
    return this.usersService.getUserByEmail$(email).pipe(
      map((user) => !!user && !user.hasAdditionalInfo),
    );
  }

  /**
   * Stores user's name for the "Remember Me" checkbox
   * @return {string} User name from local storage
   */
  getStoredUserName(): string {
    const userName = this.safeStorage.getItem(USERNAME_STORE);
    if (userName) {
      return userName;
    }
  }

  /**
   * Sets the user name in local storage
   * @param {string} userName =  the user's name
   */
  setStoredUserName(userName: string) {
    this.safeStorage.setItem(USERNAME_STORE, userName);
  }

  /**
   * Sends a password reset email to the given email address.
   *
   * @remarks
   * To complete the password reset, call {@link confirmPasswordReset}
   * with the code supplied in the email sent to the user, along with
   * the new password specified by the user.
   *
   * @param {string} email - The user's email address.
   * @param {ActionCodeSettings} actionCodeSettings
   *
   * @public
   */
  async sendPasswordResetEmail(
    email: string,
    actionCodeSettings?: ActionCodeSettings,
  ): Promise<void | AuthError> {
    return this.afAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  deleteUser$(uid: string) {
    const currentUser = firebase.auth().currentUser;

    if (currentUser.uid === uid) {
      return from(firebase.auth().currentUser?.delete().then(() => []));
    }
    return from([]);
  }

  deleteUser(uid: string) {
    const currentUser = firebase.auth().currentUser;
    if (currentUser.uid === uid) {
      return firebase.auth().currentUser.delete();
    }

    return Promise.reject(new Error('Unable to delete user'));
  }
}
